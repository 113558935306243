const data = JSON.stringify({
  total_pages: 1,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Lutire',
      description: 'Nomogram predicting recurrence risk in women with symptomatic urinary tract infection',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Number of partners*",
          answers: [
            {
              id: 1,
              desc: "",
              name: "1",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "2",
              points: 20
            },
            {
              id: 3,
              desc: "",
              name: "≥3",
              points: 35
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Bowel function",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Normal",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Diarrhea",
              points: 30
            },
            {
              id: 3,
              desc: "",
              name: "Constipation",
              points: 50
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Pathogens",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Gram positive",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Gram Negative",
              points: 40
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Hormonal status",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Fertility",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Postmenopausal",
              points: 10
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Number of UTIr*",
          answers: [
            {
              id: 1,
              desc: "",
              name: "<3",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: ">3",
              points: 40
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "AB treatment",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Yes",
              points: 50
            }
          ]
        },
      ]
    },
  ],
})

export default data
